import { gql } from '@apollo/client';
import {
  CONTACT_FRAGMENT,
  CONTACT_GRID_FRAGMENT,
  CONTENT_FRAGMENT,
  CTA_CONTACT_FRAGMENT,
  FAQ_FRAGMENT,
  IMAGE_TEXT_LIST_FRAGMENT,
  PAGE_BANNER_FRAGMENT,
  PAGE_LINK_ITEM_FRAGMENT,
  PROFESSION_FRAGMENT,
  USP_FRAGMENT,
} from './fragments';

export const BASE_QUERY = gql`
  {
    navigation: allArticlePage(
      where: {
        AND: [
          { level_any: [2, 3] }
          { showInNavigation: true, campaignPage: false }
        ]
      }
    ) {
      edges {
        node {
          id
          name
          slug
          level
          sortOrder
          parent {
            id
            name
          }
        }
      }
    }
    base: home(url: "/hem/") {
      home_id: id
      home_name: name
      home_title: title

      errorPageTitle
      errorPageIntroText
      errorPageLinks {
        ...PageLinkItemFragment
      }
      errorPageMedia {
        url
      }
      footerRichText
      footerModalRichText
      footerImage {
        url
      }
      footerLogos {
        url
        sortOrder
      }
      footerCompanyHeader
      footerHeadQuarterHeader
      footerJobbSeekerHeader
      footerOffice
      footerCompanyLinks {
        ...PageLinkItemFragment
      }
      footerImportantLinks {
        ...PageLinkItemFragment
      }
      footerJobSeekerLinks {
        ...PageLinkItemFragment
      }
      linkedinUrl
      facebookUrl
      youtubeUrl
      instagramUrl
      myPagesUrl
      contactUrl
      redirects
    }
    campaigns: allArticlePage(where: { campaignPage: true }) {
      edges {
        node {
          slug
        }
      }
    }
    hideLogos: allArticlePage(where: { hideLogo: true }) {
      edges {
        node {
          slug
        }
      }
    }
  }
  ${PAGE_LINK_ITEM_FRAGMENT}
`;

export const HOME_QUERY = gql`
  {
    home(url: "/hem/") {
      id
      name
      title
      desktopMedia {
        url
      }
      desktopVideo {
        url
      }
      mobileMedia {
        url
      }
      mobileVideo {
        url
      }
      seoTitle
      seoDescription
      ogTitle
      hidePage
      excludeLinks
      ogDescription
      ogImage {
        url
      }

      sections {
        ...PageBannerFragment
        ...ContactGridFragment
        ...ImageTextListFragment
        ...CtaContactFragment
        ...ContentFragment
        ...ProfessionFragment
        ...UspFragment
        ...FaqFragment
      }
    }
  }
  ${PAGE_BANNER_FRAGMENT}
  ${CONTACT_GRID_FRAGMENT}
  ${IMAGE_TEXT_LIST_FRAGMENT}
  ${CTA_CONTACT_FRAGMENT}
  ${CONTENT_FRAGMENT}
  ${PROFESSION_FRAGMENT}
  ${USP_FRAGMENT}
  ${FAQ_FRAGMENT}
  ${PAGE_LINK_ITEM_FRAGMENT}
  ${CONTACT_FRAGMENT}
`;

export const ARTICLE_PAGE_QUERY = gql`
  query ($slug: String!) {
    allArticlePage(where: { slug: $slug }) {
      edges {
        node {
          name
          slug
          seoTitle
          seoDescription
          ogTitle
          hidePage
          excludeLinks
          ogDescription
          ogImage {
            url
          }
          title
          introText
          desktopMedia {
            url
          }
          desktopVideo {
            url
          }
          mobileMedia {
            url
          }
          mobileVideo {
            url
          }
          link {
            ...PageLinkItemFragment
          }
          showInNavigation
          campaignPage
          hideLogo
          ancestors {
            edges {
              node {
                level
                name
                ... on ArticlePage {
                  slug
                }
              }
            }
          }
          sections {
            ...PageBannerFragment
            ...ContactGridFragment
            ...ImageTextListFragment
            ...CtaContactFragment
            ...ContentFragment
            ...ProfessionFragment
            ...UspFragment
            ...FaqFragment
          }
        }
      }
    }
    fallbackSeo: home(url: "/hem/") {
      seoTitle
      seoDescription
      ogTitle
      hidePage
      excludeLinks
      ogDescription
      ogImage {
        url
      }
    }
  }
  ${PAGE_BANNER_FRAGMENT}
  ${CONTACT_GRID_FRAGMENT}
  ${IMAGE_TEXT_LIST_FRAGMENT}
  ${CTA_CONTACT_FRAGMENT}
  ${CONTENT_FRAGMENT}
  ${PROFESSION_FRAGMENT}
  ${USP_FRAGMENT}
  ${FAQ_FRAGMENT}
  ${PAGE_LINK_ITEM_FRAGMENT}
  ${CONTACT_FRAGMENT}
`;

export const SITEMAP_QUERY = gql`
  {
    allArticlePage {
      edges {
        node {
          slug
          name
          level
          updateDate
          hidePage
          excludeLinks
        }
      }
    }
    home(url: "/hem/") {
      updateDate
    }
  }
`;
