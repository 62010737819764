import {
  ApolloClient,
  createHttpLink,
  DefaultOptions,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_UMBRACO_BASE_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = process.env.NEXT_PUBLIC_UMBRACO_API_KEY;

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
      "umb-project-alias": process.env.NEXT_PUBLIC_UMBRACO_ALIAS,
    },
  };
});

const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

export { client };
