import {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { BASE_QUERY } from '../api/queries';
import { client } from '../api/apolloClient';
import { Base } from '../models/base';
import { useRouter } from 'next/router';

type Context = {
  base: Base;
};

const UIContext = createContext<Context>(null);

export const UIProvider: FunctionComponent = ({ children }) => {
  const [base, setBase] = useState<Base>(null);
  const router = useRouter();

  useEffect(() => {
    if (!base) {
      fetchBase();
    }
  }, [base, router.asPath]);

  const fetchBase = async () => {
    const response = await client.query({
      query: BASE_QUERY,
    });

    const base = response.data.base;
    base.navigation = response.data?.navigation;
    base.campaigns = response.data?.campaigns;
    base.hideLogos = response.data?.hideLogos;

    setBase(base);
  };

  return (
    <UIContext.Provider
      value={{
        base: base,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export const useUI = () => useContext<Context>(UIContext);
