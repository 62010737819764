import styles from "./homeJumbo.module.scss";
import ArrowDownIcon from "../../../../public/icons/ico-arrow-down.svg";
import { MyImage } from "../../../models/base";
import { useWindowSizeBreakpoint } from "../../hooks";
import { useState } from "react";

type Props = {
  title: string;
  image: {
    desktopMedia: MyImage;
    desktopVideo?: MyImage;
    mobileMedia: MyImage;
    mobileVideo?: MyImage;
  };
  onScrollDownTapped: () => void;
};

export const HomeJumbo = ({ title, image, onScrollDownTapped }: Props) => {
  const brWidth = useWindowSizeBreakpoint();
  const [videoCanPlay, setVideoCanPlay] = useState<boolean>(false);

  if (brWidth < 1024 && videoCanPlay) {
    setVideoCanPlay(false);
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h1>{title}</h1>
        </div>
      </div>
      <div className={styles.imgWrapper}>
        <div onClick={onScrollDownTapped} className={styles.btn}>
          <ArrowDownIcon />
        </div>
        <div className={styles.imgWrapper}>
          <div
            className={styles.img}
            style={{
              backgroundImage: `url(${
                brWidth <= 1024 ? image.mobileMedia.url : image.desktopMedia.url
              })`,
            }}
          >
            {brWidth <= 1024 && image.mobileVideo && (
              <video
                className={styles.video}
                src={image.mobileVideo.url}
                playsInline
                autoPlay
                muted
                loop
              />
            )}
            {brWidth > 1024 && image.desktopVideo && (
              <video
                className={styles.video}
                src={image.desktopVideo.url}
                autoPlay
                muted
                loop
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
