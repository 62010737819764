type Props = {
  visible?: boolean;
};

export const HoverDot = ({ visible = false }: Props) => {
  return (
    <div style={visible == true ? { opacity: 1 } : {}} className="hover-dot">
      •
    </div>
  );
};
