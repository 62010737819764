import styles from "./myRichText.module.scss";

type Props = {
  richText: string;
  darkBackground?: boolean;
};

export const MyRichText = ({ richText, darkBackground = false }: Props) => {
  return (
    <div
      className={darkBackground ? styles.darkRichText : styles.richText}
      dangerouslySetInnerHTML={{
        __html: richText,
      }}
    />
  );
};
