import styles from "./myModal.module.scss";
import { useRef } from "react";
import IconClear from "../../../../public/icons/ico-X.svg";
type Props = {
  isOpen: boolean;
  onClose: Function;
  children: JSX.Element;
};

export const MyModal = ({ isOpen, onClose, children }: Props) => {
  const myRef = useRef(null);
  const animRef = useRef(null);

  const openModal = () => {
    if (myRef.current) {
      var modal = myRef.current as HTMLElement;
      var anim = animRef.current as HTMLElement;
      modal.style.display = "flex";
      setTimeout(() => {
        anim.classList.add(styles.in);
        return onClose();
      }, 1);
    }
  };

  const closeModal = () => {
    if (myRef.current) {
      var modal = myRef.current as HTMLElement;
      var anim = animRef.current as HTMLElement;
      anim.classList.remove(styles.in);
      setTimeout(() => {
        modal.style.display = "none";
        return onClose();
      }, 500);
    }
  };
  if (isOpen) {
    openModal();
  }

  return (
    <div ref={myRef} className={styles.modal}>
      <div className={styles.backdrop}></div>
      <div ref={animRef} className={styles.modalContent}>
        <div onClick={() => closeModal()} className={styles.closedrop}></div>
        <div className={styles.container}>
          <div className={styles.widthBox}>
            <div className={styles.closeBtn} onClick={() => closeModal()}>
              <IconClear />
            </div>
            <div className={styles.children}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
