export const PAGE_LINK_ITEM_FRAGMENT = `
fragment PageLinkItemFragment on PageLinkItem {
    ... on PageLinkItem {
        page {
          ... on ArticlePage {
            name
            title
            slug
            desktopMedia {
                url
            }
            mobileMedia {
                url
            }
          }
        }    
        externalUrl
        title
        image {
            url
        }
    }
  }
  `;

export const PAGE_BANNER_FRAGMENT = `
fragment PageBannerFragment on PageBannerSection {
    ... on PageBannerSection {
      title
      desktopMedia {
        url
      }
      mobileMedia {
        url
      }
      links {
        ...PageLinkItemFragment
      }
      boxPosition
    }
  }
  `;

export const CONTACT_GRID_FRAGMENT = `
fragment ContactGridFragment on ContactGridSection {
    ... on ContactGridSection {
      title
      contacts {
        ...ContactFragment
      }
      backgroundColor
      showContactInfo
    }
  }
  `;

export const IMAGE_TEXT_LIST_FRAGMENT = `
fragment ImageTextListFragment on ImageTextListSection {
    ... on ImageTextListSection {
      titleRichText
      imageTextItems {
        ... on ImageTextItem {
          image {
            url
          }
          richText
        }
      }
      backgroundColor
      slider
    }
  }
  `;

export const CTA_CONTACT_FRAGMENT = `
  fragment CtaContactFragment on CtaContactSection {
    ... on CtaContactSection {
      title
      contact {
        ...ContactFragment
      }
      backgroundColor
    }
  }
  `;

export const CONTENT_FRAGMENT = `
  fragment ContentFragment on ContentSection {
    ... on ContentSection {
      richText
      backgroundColor
      fullWidth
    }
  }
  `;

export const PROFESSION_FRAGMENT = `
fragment ProfessionFragment on ProfessionSection {
    ... on ProfessionSection {
      titleTop
      titleBottom
      items {
        ...PageLinkItemFragment
      }
    }
  }
  `;

export const USP_FRAGMENT = `
fragment UspFragment on UspSection {
    ... on UspSection {
      titleTop
      uspItems {
        ...UspItemFragment
      }
    }
  }

  fragment UspItemFragment on UspItem {
      ... on UspItem {
        title
        description
    }
  }
  `;

export const FAQ_FRAGMENT = `
fragment FaqFragment on FaqSection {
    ... on FaqSection {
      title
      faqItems {
        ...FaqItemFragment
      }
      backgroundColor
    }
  }

  fragment FaqItemFragment on FaqItem {
      ... on FaqItem {
        title
        richText
    }
  }
  `;

export const CONTACT_FRAGMENT = `
fragment ContactFragment on ContactItem {
    ... on ContactItem {
        id
        fullName
        image {
        url
        }
        workTitle
        phone
        email
    }
  }
  `;
