import styles from './pageWrapper.module.scss';
import { Footer } from './footer/footer';
import { useDidMountEffect, useWindowSize } from '../../hooks';
import { useEffect, useState } from 'react';
import { useUI } from '../../../context/ui';
import { MyModal } from '../myModal/myModal';
import { Menu } from './menu/menu';
import { MyRichText } from '../myRichText/myRichText';

type Props = {
  children?: JSX.Element;
};

export const PageWrapper = ({ children }: Props) => {
  const { base } = useUI();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <Menu />
      <div className={styles.pageContainer}>
        <div id='content' className={styles.contentContainer}>
          {children}
        </div>
        <Footer openModal={() => setIsModalOpen(true)} />
        {base?.footerModalRichText && (
          <MyModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <MyRichText richText={base?.footerModalRichText} />
          </MyModal>
        )}
      </div>
    </>
  );
};
