import styles from "./mySocialIconButton.module.scss";
import FbIcon from "../../../../public/icons/ico-facebook.svg";
import LinkedinIcon from "../../../../public/icons/ico-linkedin.svg";
import YoutubeIcon from "../../../../public/icons/ico-youtube.svg";
import InstagramIcon from "../../../../public/icons/ico-instagram.svg";
import Link from "next/link";

type Props = {
  icon: String;
  href: string;
};

export const MySocialIconButton = ({ icon, href }: Props) => {
  function getIconFromKey() {
    switch (icon.toLowerCase()) {
      case "facebook":
        return <FbIcon />;
      case "linkedin":
        return <LinkedinIcon />;
      case "youtube":
        return <YoutubeIcon />;
      case "instagram":
        return <InstagramIcon />;
      default:
        return <FbIcon />;
    }
  }

  return (
    <Link href={href}>
      <a
        className={styles.outerCircle}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={styles.icon}>{getIconFromKey()}</div>
      </a>
    </Link>
  );
};
