import { ControlledMenu, MenuItem, useMenuState } from "@szhsin/react-menu";

import Link from "next/link";
import { useRef } from "react";
import { HoverDot } from "../..";
import styles from "./menu.module.scss";

type Props = {
  item: any;
  subItems: any;
};

export const MyMenuItem = ({ item, subItems }: Props) => {
  const ref = useRef(null);
  const [menuProps, toggleMenu] = useMenuState({ transition: true });

  if (!item) return null;

  return (
    <>
      <div onMouseLeave={() => toggleMenu(false)}>
        <a href={`/${item.node.slug}`}>
          <div
            className={styles.menuItem}
            ref={ref}
            onMouseEnter={() => toggleMenu(true)}
          >
            {item.node.name}
            <HoverDot
              visible={
                menuProps.state == "open" || menuProps.state == "opening"
              }
            />
          </div>
        </a>

        {subItems?.length > 0 && (
          <ControlledMenu
            {...menuProps}
            menuClassName={styles.subMenu}
            anchorRef={ref}
            align="center"
            onMouseLeave={() => toggleMenu(false)}
            onClose={() => toggleMenu(false)}
          >
            <div className={styles.myArrow}></div>
            <div className={styles.linksWrapper}>
              {subItems.map((subItem: any, index: number) => (
                <a key={index} href={`/${subItem.node.slug}`}>
                  <MenuItem className={styles.subMenuItem}>
                    <div className={styles.subMenuItemText}>
                      {subItem.node.name}
                    </div>
                  </MenuItem>
                </a>
              ))}
            </div>
          </ControlledMenu>
        )}
      </div>
    </>
  );
};
