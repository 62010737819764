import styles from './menu.module.scss';
import { useUI } from '../../../../context/ui';
import { MyMenuItem } from './myMenuItem';
import { HoverDot } from '../..';
import Image from 'next/image';
import BurgerIcon from '../../../../../public/icons/ico-menu.svg';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

export const Menu = () => {
  const { base } = useUI();
  const router = useRouter();
  const [scrollY, setScrollY] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const campaignPage =
    base?.campaigns.edges.some(
      (edge: any) => router.query.slug == edge.node.slug
    ) ?? true;

  const hideLogo =
    base?.hideLogos.edges.some(
      (edge: any) => router.query.slug == edge.node.slug
    ) ?? true;

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    handleScroll();

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const Navbar = () => {
    return (
      <nav>
        <ul className={styles.menuItems}>
          {base?.navigation.edges
            .filter((item: any) => item.node.level == 2)
            .map((item: any, index: number) => {
              const subItems = base?.navigation.edges.filter(
                (subItem: any) => subItem.node.parent.id == item.node.id
              );

              return <MyMenuItem item={item} subItems={subItems} key={index} />;
            })}
        </ul>
      </nav>
    );
  };

  const renderDrawer = () => {
    return (
      <>
        <div
          className={styles.drawerBtn}
          onClick={() => setDrawerOpen(!drawerOpen)}
        >
          <div
            className={`${styles.burger} ${drawerOpen ? styles.isOpen : ''}`}
          >
            <BurgerIcon />
          </div>
        </div>
        <div
          className={`${styles.backdrop} ${drawerOpen ? styles.isOpen : ''}`}
          onClick={() => setDrawerOpen(false)}
        ></div>
        <div
          className={`${styles.drawerMenu} ${drawerOpen ? styles.isOpen : ''}`}
        >
          {[
            ...base?.navigation.edges,
            {
              node: {
                id: 'MySuperID',
                level: 2,
                name: 'Kontakta oss',
                parent: {
                  id: base?.home_id,
                  name: base?.home_name,
                  __typename: base?.__typename,
                },
                slug: base?.contactUrl.split('/')[1] ?? '/',
                sortOrder: 4,
                __typename: 'ArticlePage',
              },
              __typename: 'ArticlePageEdge',
            },
          ]
            .filter((item: any) => item.node.level == 2)
            .map((item: any, index: number) => {
              const subItems = base?.navigation.edges.filter(
                (subItem: any) => subItem.node.parent.id == item.node.id
              );

              return renderDrawerLink(item, subItems, index);
            })}
        </div>
      </>
    );
  };

  const renderDrawerLink = (item: any, subItems: any, index: number) => {
    return (
      <div key={index} className={styles.drawerLink}>
        <div className={styles.rootItem}>
          <a href={`/${item.node.slug}`}>{item.node.name}</a>
        </div>

        {subItems.map((subItem: any, index: number) => {
          return (
            <div key={index} className={styles.subItem}>
              <a href={`/${subItem.node.slug}`}>{subItem.node.name}</a>
            </div>
          );
        })}
      </div>
    );
  };

  const renderLogo = () => {
    return (
      <a href={'/'}>
        <div className={styles.logo}>
          <div
            style={{
              opacity: scrollY > 20 ? 1 : 0,
              zIndex: scrollY > 20 ? 11 : 10,
            }}
            className={styles.aLogo}
          >
            <Image src='/images/a-logo.png' layout='fill' alt='a' />
          </div>
          <div
            style={{
              opacity: scrollY > 20 ? 0 : 1,
              cursor: scrollY > 20 ? 'auto' : 'pointer',
              zIndex: scrollY > 20 ? 10 : 11,
            }}
            className={styles.agilloLogo}
          >
            <Image src='/images/agillo-logo.png' layout='fill' alt='agillo' />
          </div>
        </div>
      </a>
    );
  };

  return (
    <>
      <header>
        <div className={styles.header}>
          {!hideLogo && renderLogo()}
          {!campaignPage && <Navbar />}
          {!campaignPage && (
            <Link href={base?.contactUrl ?? '/'}>
              <a>
                <div className={styles.contactBtn}>
                  {'Kontakta oss'}
                  <HoverDot />
                </div>
              </a>
            </Link>
          )}
        </div>
      </header>
      {!campaignPage && renderDrawer()}
    </>
  );
};
