import styles from "./articleJumbo.module.scss";
import ArrowDownIcon from "../../../../public/icons/ico-arrow-down.svg";
import { BaseLink, MyImage } from "../../../models/base";
import { useWindowSizeBreakpoint } from "../../hooks";
import Link from "next/link";
import ArrowUpRightIcon from "../../../../public/icons/ico-arrow-up-right.svg";
import HeartIcon from "../../../../public/icons/ico-heart.svg";
import ChevronRightIcon from "../../../../public/icons/ico-chevron-right.svg";
import { useRouter } from "next/router";

type Props = {
  title: string;
  description?: string;
  links?: BaseLink[];
  hideBreadcrumbs: boolean;
  breadcrumbs: any[];
  image: {
    desktopMedia: MyImage;
    desktopVideo?: MyImage;
    mobileMedia: MyImage;
    mobileVideo?: MyImage;
  };
  scrollDownTapped: () => void;
  hideScrollDownBtn?: boolean;
};

export const ArticleJumbo = ({
  title,
  description,
  hideBreadcrumbs,
  breadcrumbs,
  links = [],
  image,
  hideScrollDownBtn = false,
  scrollDownTapped,
}: Props) => {
  const router = useRouter();
  const brWidth = useWindowSizeBreakpoint();

  return (
    <div
      style={{
        flexDirection:
          !image.desktopMedia && !image.mobileMedia ? "column" : null,
        height:
          image.desktopMedia && image.mobileMedia && brWidth > 1639
            ? "100vh"
            : "auto",
      }}
      className={styles.container}
    >
      <div className={styles.contentContainer}>
        <div
          style={{
            maxWidth:
              brWidth > 1639 && image.desktopMedia && `calc(50% - 80px)`,
          }}
          className={styles.content}
        >
          {!hideBreadcrumbs && breadcrumbs.length > 1 && (
            <div className={styles.breadcrumbs}>
              {breadcrumbs.map((val: any, index: number) => {
                return (
                  <div key={index} className={styles.row}>
                    <div
                      className={`${styles.item} ${
                        val.node.__typename !== "Home" &&
                        router.asPath.split("/")[1] !== val.node.slug
                          ? "underlineActive"
                          : null
                      }`}
                    >
                      {router.asPath.split("/")[1] === val.node.slug ? (
                        <span>{val.node.name}</span>
                      ) : (
                        <Link
                          href={
                            val.node.__typename === "Home"
                              ? process.env.NEXT_PUBLIC_BASE_URL
                              : process.env.NEXT_PUBLIC_BASE_URL +
                                "/" +
                                val.node.slug
                          }
                        >
                          <a
                            style={{
                              cursor:
                                router.asPath.split("/")[1] === val.node.slug
                                  ? "default"
                                  : "",
                            }}
                            target="_self"
                            rel="noreferrer"
                          >
                            {val.node.__typename === "Home" ? (
                              <div className={styles.heart}>
                                <HeartIcon />
                              </div>
                            ) : (
                              val.node.name
                            )}
                          </a>
                        </Link>
                      )}
                    </div>
                    {breadcrumbs.length - 1 !== index && (
                      <div className={styles.arrow}>
                        <ChevronRightIcon />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          <div className={styles.header}>
            <h1>{title}</h1>
            {description && <p>{description}</p>}
          </div>
          {links?.length > 0 && (
            <div className={styles.linkCol}>
              <hr />
              {links.map((val: BaseLink, index: number) => {
                return (
                  <div key={index}>
                    <Link
                      href={
                        val.externalUrl
                          ? val.externalUrl
                          : process.env.NEXT_PUBLIC_BASE_URL +
                            "/" +
                            val.page.slug
                      }
                    >
                      <a
                        className={styles.linkItem}
                        target={val.externalUrl ? "_blank" : "_self"}
                        rel="noreferrer"
                      >
                        {val.title}
                        <div className={styles.icon}>
                          <ArrowUpRightIcon />
                        </div>
                      </a>
                    </Link>
                    <hr />
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {image.desktopMedia &&
          image.mobileMedia &&
          brWidth < 1639 &&
          !hideScrollDownBtn && (
            <div onClick={scrollDownTapped} className={styles.btn}>
              <ArrowDownIcon />
            </div>
          )}
      </div>
      {image.desktopMedia && image.mobileMedia && (
        <div className={styles.imgWrapper}>
          <div
            className={styles.img}
            style={{
              backgroundImage: `url(${
                brWidth <= 1024 ? image.mobileMedia.url : image.desktopMedia.url
              })`,
            }}
          >
            {brWidth <= 1024 && image.mobileVideo && (
              <video
                className={styles.video}
                src={image.mobileVideo.url}
                autoPlay
                muted
                loop
              />
            )}
            {brWidth > 1024 && image.desktopVideo && (
              <video
                className={styles.video}
                src={image.desktopVideo.url}
                autoPlay
                muted
                loop
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
