import { MySocialIconButton } from "../../mySocialIconButton/mySocialIconButton";
import styles from "./footer.module.scss";
import IconRight from "../../../../../public/icons/ico-arrow-right.svg";
import IconUser from "../../../../../public/icons/ico-user.svg";
import Image from "next/image";
import Link from "next/link";
import { useUI } from "../../../../context/ui";
import { BaseLink, Logo } from "../../../../models/base";

type Props = {
  openModal: () => void;
};

export const Footer = ({ openModal }: Props) => {
  const { base } = useUI();

  const renderTopContent = () => {
    return (
      <div className={styles.topContent}>
        <div className={styles.socialRow}>
          {base?.instagramUrl && (
            <MySocialIconButton icon="linkedin" href={base?.linkedinUrl} />
          )}
          {base?.facebookUrl && (
            <MySocialIconButton icon="facebook" href={base?.facebookUrl} />
          )}
          {base?.instagramUrl && (
            <MySocialIconButton icon="instagram" href={base?.instagramUrl} />
          )}
          {base?.youtubeUrl && (
            <MySocialIconButton icon="youtube" href={base?.youtubeUrl} />
          )}
        </div>
        {base?.footerImage && (
          <div className={styles.imgWrapper}>
            <Image
              className={styles.img}
              src={base?.footerImage.url}
              alt="Logo"
              layout="fill"
              objectFit="cover"
            />
          </div>
        )}
      </div>
    );
  };

  const renderMiddleContent = () => {
    return (
      <div className={styles.middleContent}>
        <div className={styles.mTopContent}>
          <div className={styles.mTopCol}>
            {base?.footerRichText && (
              <div
                className={styles.richText}
                dangerouslySetInnerHTML={{
                  __html: base?.footerRichText,
                }}
              />
            )}
            <div className={styles.logoRow}>
              {base?.footerLogos &&
                base?.footerLogos.map((src: Logo, index: number) => {
                  return (
                    <div className={styles.imgWrapper} key={index}>
                      <Image
                        src={src.url}
                        alt="Logo"
                        layout="fill"
                        objectFit="contain"
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={styles.mBottomContent}>
          {renderHq()}
          {base?.footerCompanyLinks &&
            base?.footerCompanyHeader &&
            renderContentLists(
              base?.footerCompanyHeader,
              base?.footerCompanyLinks
            )}
          {base?.footerJobSeekerLinks &&
            base?.footerJobbSeekerHeader &&
            renderContentLists(
              base?.footerJobbSeekerHeader,
              base?.footerJobSeekerLinks
            )}
        </div>
      </div>
    );
  };

  const renderHq = () => {
    return (
      <div className={styles.mTop}>
        <p className={styles.header}>{base?.footerHeadQuarterHeader}</p>
        <hr />

        {base?.footerOffice && (
          <div
            dangerouslySetInnerHTML={{
              __html: base?.footerOffice,
            }}
          />
        )}
      </div>
    );
  };

  const renderContentLists = (title: any, list: BaseLink[]) => {
    return (
      <div className={styles.listCol}>
        <p className={styles.header}>{title}</p>
        <hr />
        {list &&
          list.map((value: BaseLink, index: number) => {
            return (
              <div key={index} className={styles.item}>
                <Link
                  href={
                    value.externalUrl
                      ? value.externalUrl
                      : process.env.NEXT_PUBLIC_BASE_URL + "/" + value.page.slug
                  }
                >
                  <a
                    className={styles.listItem}
                    target={value.externalUrl ? "_blank" : "_self"}
                    rel="noreferrer"
                  >
                    <p>{value.title}</p>
                    <span className={styles.icon}>
                      <IconRight />
                    </span>
                  </a>
                </Link>
                <hr />
              </div>
            );
          })}
      </div>
    );
  };

  const renderBottomContent = () => {
    return (
      <div className={styles.bottomContent}>
        <div className={styles.bRow}>
          <p>&copy;{new Date().getFullYear()} Agillo AB</p>
          {base?.footerImportantLinks &&
            base?.footerImportantLinks.map((value: BaseLink, index: number) => {
              return (
                <div className={styles.bRow} key={index}>
                  {renderDot()}
                  <Link
                    href={
                      value.externalUrl
                        ? value.externalUrl
                        : process.env.NEXT_PUBLIC_BASE_URL +
                          "/" +
                          value.page.slug
                    }
                  >
                    <a
                      className={styles.link}
                      target={value.externalUrl ? "_blank" : "_self"}
                      rel="noreferrer"
                    >
                      {value.title}
                    </a>
                  </Link>
                </div>
              );
            })}
        </div>
        {base?.myPagesUrl && (
          <Link href={base.myPagesUrl}>
            <a
              className={styles.btn}
              target={
                base.myPagesUrl.includes(process.env.NEXT_PUBLIC_BASE_URL)
                  ? "_self"
                  : "_blank"
              }
              rel="noreferrer"
            >
              <span className={styles.icon}>
                <IconUser />
              </span>
              <p>Mina sidor</p>
            </a>
          </Link>
        )}
      </div>
    );
  };

  const renderDot = () => {
    return <div className={styles.dot}></div>;
  };

  const renderSubscribeContent = () => {
    return (
      <div onClick={openModal} className={styles.row}>
        <p>Prenumerera på vårt nyhetsbrev</p>
        <span className={styles.icon}>
          <IconRight />
        </span>
      </div>
    );
  };

  return (
    <footer id="footer" className={styles.container}>
      <div className={styles.subContainer}>
        <div className={styles.widthBox}>{renderSubscribeContent()}</div>
      </div>
      <div className={styles.content}>
        <div className={styles.widthBox}>
          {renderTopContent()}
          {renderMiddleContent()}
          {renderBottomContent()}
        </div>
      </div>
    </footer>
  );
};
