import "../styles/globals.scss";
import type { AppProps } from "next/app";
import { PageWrapper } from "../lib/components";
import { UIProvider } from "../lib/context/ui";
import PlausibleProvider from "next-plausible";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { pageview } from "../lib/utils/gaHelper";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { ParallaxProvider } from "react-scroll-parallax";

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    // FACEBOOK PIXEL - Loaded from GTM Instead
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init("717098366382455");
        ReactPixel.pageView();
        router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      });

    // GTM
    const handleRouteChange = (url: string) => {
      pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, [router.events]);

  return (
    <PlausibleProvider
      enabled={process.env.NEXT_PUBLIC_ENVIRONMENT == "prod"}
      domain="agillo.se"
    >
      <UIProvider>
        <ParallaxProvider>
          <PageWrapper>
            <Component {...pageProps} />
          </PageWrapper>
        </ParallaxProvider>
      </UIProvider>
    </PlausibleProvider>
  );
}

export default MyApp;
